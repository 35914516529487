import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet, ScrollView, TouchableOpacity, Platform } from "react-native";
import CloseIcon from '@mui/icons-material/CloseOutlined';
// Customizable Area End

import Emailnotifications2Controller, {
  Props,
  configJSON,
} from "./Emailnotifications2Controller";

export default class Emailnotifications2 extends Emailnotifications2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={[styles.container, this.props.visible && styles.containerAnimated]}>
        {
          this.state.notifications.length <= 0 ? (
            <TouchableOpacity style={styles.notificationContainer}>
              <View style={styles.notificationContent}>
                <Typography variant="base" style={styles.title}>No Notifications Yet</Typography>
              </View>
            </TouchableOpacity>
          ) : (
            <>
              <TouchableOpacity testID="btnClearAll" style={styles.clearAllButton} onPress={this.clearAllNotifications}>
                <Typography style={styles.clearAllButtonText} variant="sm">Clear all</Typography>
              </TouchableOpacity>
              <ScrollView>
                {
                  this.state.notifications.map(notification => {
                    return (
                      <TouchableOpacity testID={`btnNotification-${notification.id}`} key={notification.id} style={styles.notificationContainer} onPress={() => this.selectedNotification(notification.id, notification.account.id, { city_name: notification.account.city_name, latitude: notification.account.latitude, longitude: notification.account.longitude })}>
                        <TouchableOpacity testID={`btnDeleteNotification-${notification.id}`} onPress={() => this.deleteNotification(notification.id)}>
                          <CloseIcon sx={{ fontSize: 18 }} />
                        </TouchableOpacity>
      
                        <View style={styles.notificationContent}>
                          <Typography variant="base" style={{...styles.title, fontWeight: notification.is_read ? "400" : "700"}}>{notification.headings}</Typography>
                          <Typography variant="sm" style={{...styles.subTitle, fontWeight: notification.is_read ? "400" : "700"}}>{notification.contents}</Typography> 
                        </View>
      
                        <img src={notification.account.profile_image} style={styles.profilePicture} /> 
                      </TouchableOpacity>
                    )
                  })
                }
              </ScrollView>
            </>
          ) 
        }
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    display: "none",
    top: 90,
    zIndex: 999,
    maxHeight: 380,
    opacity: 0,
    ...Platform.select({
      web: {
        transition: 'right 0.3s ease, max-width 0.3s ease, opacity 0.3s ease',
      }
    }),
  },
  containerAnimated: {
    opacity: 1,
    right: 30,
    display: "flex"
  },
  notificationContainer: {
    width: 327,
    backgroundColor: "#9BCE7B",
    flexDirection: "row",
    borderRadius: 6,
    paddingHorizontal: 20,
    marginVertical: 8,
    marginHorizontal: 10,
    paddingVertical: 16,
    ...Platform.select({
      web: {
        gap: 8,
        boxShadow: "3px 3px 3px rgba(0, 0, 0, 0.35)",
      }
    }),

  },
  notificationContent: {
    flex: 1,
  },
  profilePicture: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: "#F0E5FF",
    borderStyle: "solid"
  },
  title: {
    fontWeight: "700",
    color: "#0F172A",
    marginBottom: 2
  },
  subTitle: {
    fontWeight: "400",
    color: "#0F172A"
  },
  clearAllButton: {
    marginLeft: "auto",
    marginRight: 30
  },
  clearAllButtonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#FFF"
  },
});


// Customizable Area End
