import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Linking, Platform, PermissionsAndroid } from "react-native";
import Geolocation from 'react-native-geolocation-service';
import React from "react";
import { StoreType } from "../../maps/src/MapsController";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  user_type: string;
  store: StoreType;
  onCloseStoreLocator: () => void;
  onOpenGallery: (photos: Array<{ url: string }>) => void;
  showOnTheMap: () => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  inputSearchText: string;
  initialRegion: any;
  searchText: string;
  mainMarker: any;
  showStoreInfoModal: boolean;
  storeInfo: any;
  showMapsIcon: boolean;
  rememberChoice: boolean;
  loader: boolean;
  token: string;
  machinesList: Array<{}>;
  isSearchBoxFocused: boolean;
  platform: string;
  favourite: {
    id: string;
    type: string;
    attributes: {
      favouriteable_id:	number;
      favouriteable_type: string;
      user_id: number;
      business_account_details: {
        id: number;
        user_name: string;
      }	
    profile_image: Array<{ url: string }>;
    show_open: string;
    }
  } | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StoreLocatorController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  inputSearchRef = React.createRef<HTMLInputElement>()
  searchInputRef: any;
  getVendingMachinesAPICallID: string = "";
  getVendingMachinesBySearchAPICallID: string = "";
  favouriteStoreAPICallID: string = "";
  unFavouritesApiCallId: string = "";
  favouritesApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      inputSearchText: "",
      initialRegion: {},
      searchText: "",
      mainMarker: null,
      showStoreInfoModal: false,
      showMapsIcon: false,
      storeInfo: {},
      rememberChoice: false,
      loader: true,
      token: "",
      machinesList: [],
      isSearchBoxFocused: false,
      platform: Platform.OS,
      favourite: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({
          token
        }, () => {
          this.getFavourites()
        })
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponseApi(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };
  
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };
  
  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let dataMessage = new Message(getName(MessageEnum.AccoutLoginSuccess));
    dataMessage.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(dataMessage);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevProps.store.id !== this.props.store.id && this.state.token) { 
      this.getFavourites()
    }
  }

  private handleResponseApi = (message: Message) => {
    const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );

    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if(apiRequestCallId === this.getVendingMachinesAPICallID || apiRequestCallId ===  this.getVendingMachinesBySearchAPICallID){
      this.storeListofMachines(responseJson);
    }

    if (apiRequestCallId === this.favouriteStoreAPICallID) {
      this.setState({
        favourite: responseJson.data,
      })
    }

    if (apiRequestCallId === this.unFavouritesApiCallId) {
      this.setState({
        favourite: null,
      })
    }

    if (apiRequestCallId === this.favouritesApiCallId) {
      if (responseJson.data) {
        this.setState({
          favourite: responseJson.data.find((favourite: any) => favourite.attributes.business_account_details.id === this.props.store.id) || null
        })
      }
    }
  }

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };

  getFavourites = () => {
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.favouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouritesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnOpenMap = {
    onPress: () => this.props.showOnTheMap()
  }

  async componentDidMount() {
    this.focusMethod();
    this.getToken();
  }
  
  markerPressed = (marker:object) => {
    this.setState({showStoreInfoModal: true, storeInfo: marker})
  }

  closeModal = () => {
    this.setState({showStoreInfoModal: false, storeInfo: {}, showMapsIcon: false})
  }

  changeRememberChoice = () => {
    this.setState({rememberChoice: !this.state.rememberChoice})
  }

  goHerePressed = () => {
    if(this.state.platform === "android") 
    {
      this.setState({showStoreInfoModal: false })
      this.openGoogleMaps()
    }
    else
      this.setState({showStoreInfoModal: false, showMapsIcon: true})
  }

  searchTextChange = async(text: string) => {
    this.setState({searchText: text, loader: true, isSearchBoxFocused: false})
    if(text.length > 0){
      let token = "USER_TOKEN" //await StorageProvider.get("USER_TOKEN");
      this.apiCallIDForVendingMachines(token, text.toLowerCase())
    }
    else{
      this.getVendingMachines();
    }
  }

  apiCallIDForVendingMachines = (token: string, text: string) => {
    const header = {
      "Content-Type":"application/json",
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getVendingMachinesBySearchAPICallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.searchVendingMachinesEndpoint+text
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  favouriteStore = () => {
    const header = {
      "Content-Type":"application/json",
      token: this.state.token
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.favouriteStoreAPICallID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.favouriteStoreEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        favourites: {
          account_id: this.props.store.id
        }
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unfavouriteStore = () => {
    const header = {
      "Content-Type": configJSON.favouritesApiApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unFavouritesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unFavouriteStoreEndPoint}/${this.state.favourite?.id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  openMaps = () => {
    const scheme = 'maps:0,0?q='
    const latLng = `${this.state.storeInfo.attributes.latitude},${this.state.storeInfo.attributes.longitude}`;
    const label = this.state.storeInfo.attributes.title
    const mapUrl = `${scheme}${label}@${latLng}`
    Linking.openURL(mapUrl)
  }

  openGoogleMaps = () => {
      const scheme = 'geo:0,0?q='
      const latLng = `${this.state.storeInfo.attributes.latitude},${this.state.storeInfo.attributes.longitude}`;
      const label = this.state.storeInfo.attributes.title
      const urlGoogleMaps = `${scheme}${latLng}(${label})`
      Linking.openURL(urlGoogleMaps)
  }

  getCurrentLocation = () => {
    this.setState({ searchText: "" })
    Geolocation.getCurrentPosition(
      (position) => {
        const {latitude,longitude } = position.coords
        this.setState({ mainMarker : {latitude, longitude},   });
        this.getVendingMachines();
      },
      (error) => {
        alert("Unable to track your location. "+ error) 
        this.getVendingMachines();
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
    )
  }

  getVendingMachines = async() => {
      const header = {
        "Content-Type":"application/json",
      }
      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.getVendingMachinesAPICallID=requestMessage.messageId
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getVendingMachinesEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    
  }

  storeListofMachines = (responseJson:any) => {
    if(responseJson.data){
      if(this.state.searchText !== "" ){
        this.setState({ initialRegion : {
          latitude: responseJson.data[0].attributes.latitude,
          longitude: responseJson.data[0].attributes.longitude,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }})
      } else if(this.state.mainMarker){
        this.setState({ initialRegion : {
          latitude: this.state.mainMarker.latitude,
          longitude: this.state.mainMarker.longitude,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }})
      }
      
      this.setState({ machinesList : [...responseJson.data], loader: false })
    }
    else{
      this.setState({ machinesList : [], loader: false })
      alert(responseJson.message)
    }
  }

  hasLocationPermissions = async () => {
    const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      return true;
    } else { 
      return false;
    }
  }

  checkIOSPermissions = (result: string) => {
    if (result === 'granted') {
      this.getCurrentLocation();
    }
    else{
      this.getVendingMachines();
    }
  }

  checkAndroidSide = (permission: boolean) => {
    if(permission){
      this.getCurrentLocation();
    }else {
      this.showAlert(
        "Alert",
        "Unable to track your Location, Permissions denied"
      );
      this.getVendingMachines();
    }
  }

  focusMethod = async () => {
    this.setState({loader : true})
    if(this.state.platform === 'ios'){
      let result = await Geolocation.requestAuthorization('whenInUse')
      this.checkIOSPermissions(result)
    }
    if(this.state.platform === 'android'){
      const permission = await this.hasLocationPermissions();
      this.checkAndroidSide(permission);
    }
  }

  changeTextSearch = (text:string) => {
    this.setState({searchText: text})
  }

  onFocus = () => {
    this.setState({isSearchBoxFocused : true})
  }
  // Customizable Area End
}
