import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet, TouchableOpacity, ScrollView } from "react-native";
import { subscriptionImage } from "./assets";
import ArrowLeftIcon from '@mui/icons-material/ArrowBackOutlined';
import PlanCard from "../../../components/src/PlanCard";
// Customizable Area End

import Subscriptionbilling2Controller, {
  Props,
  configJSON,
} from "./Subscriptionbilling2Controller";

export default class Subscriptionbilling2 extends Subscriptionbilling2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.screen}>
        <ScrollView>
          <View style={styles.main}>
            <View style={styles.content}>
              <View style={styles.subscriptionInformation}>
                <View style={styles.subscriptionInformationHeader}>
                  <TouchableOpacity style={styles.goBackContainer} testID="btnGoBack" onPress={() => this.goToCustomisableUserSubscriptions()}>
                    <ArrowLeftIcon sx={styles.goBackIcon} />
                  </TouchableOpacity>
                  <Typography variant="xl" style={styles.subscriptionInformationHeaderTitle}>Billing information</Typography>
                </View>

                <Typography variant="xs" style={styles.subscriptionInformationText}>BILLING CYCLE</Typography>
                
                <View style={styles.subscriptionType}>
                  {
                    this.state.plan.attributes.plan_type !== "Event" && !!this.state.plan.attributes.monthly_price && this.state.plan.attributes.monthly_price !== "0.0" && (
                      <TouchableOpacity
                        testID="btnSelectMonthlyCycle"
                        style={this.state.selectedSubscriptionPaymentType === "monthly" ? styles.paymentTypeButtonRadioSelected : styles.paymentTypeButtonRadio}
                        onPress={() => this.setState({ selectedSubscriptionPaymentType: "monthly" })}
                      >
                        <View style={this.state.selectedSubscriptionPaymentType === "monthly" ? styles.paymentTypeRadioSelected : styles.paymentTypeRadio} />
                        <View style={styles.buttonRadioContent}>
                          <Typography variant="base" style={styles.buttonRadioTitle}>Pay monthly</Typography>
                          <Typography variant="xs" style={styles.buttonRadioSubtitle}>${this.state.plan.attributes.monthly_price}/month</Typography>
                        </View>
                      </TouchableOpacity>
                    )
                  }

                  {
                    this.state.plan.attributes.plan_type !== "Event" && !!this.state.plan.attributes.yearly_price && this.state.plan.attributes.yearly_price !== "0.0" && (
                      <TouchableOpacity
                        testID="btnSelectYearlyCycle"
                        style={this.state.selectedSubscriptionPaymentType === "yearly" ? styles.paymentTypeButtonRadioSelected : styles.paymentTypeButtonRadio}
                        onPress={() => this.setState({ selectedSubscriptionPaymentType: "yearly" })}
                      >
                        <View style={this.state.selectedSubscriptionPaymentType === "yearly" ? styles.paymentTypeRadioSelected : styles.paymentTypeRadio} />
                        <View style={styles.buttonRadioContent}>
                          <Typography variant="base" style={styles.buttonRadioTitle}>Pay yearly</Typography>
                          <Typography variant="xs" style={styles.buttonRadioSubtitle}>${this.state.plan.attributes.yearly_price}/year</Typography>
                        </View>
                      </TouchableOpacity>
                    )
                  }
                </View>


                <PlanCard
                  testID="planCard"
                  planName={this.state.plan.attributes.name}
                  planDescription={this.state.plan.attributes.sub_title}
                  benefits={this.state.plan.attributes.description.split(',')}
                  monthlyPrice={this.state.plan.attributes.monthly_price}
                  yearlyPrice={this.state.plan.attributes.yearly_price}
                  planType={this.state.plan.attributes.plan_type}
                  buttonActionText={this.state.isFreetrial ? "Start free trial" :"Proceed to pay"}
                  onActionButton={() => this.proceedToPay()}
                />
              </View>


              <View style={styles.subscriptionImageContanier}>
                <img src={subscriptionImage} style={styles.subscriptionImage} />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  screen: {
    width: "100%",
    height: "100%", 
    flex: 1,
    backgroundColor: "#9BCE7B",
  },
  main: {
    flex: 1, 
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: "auto",
    width: "100%", 
    height: "100%",
    maxWidth: 1200,
    padding: 40,
  },
  content: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  subscriptionInformationText: {
    fontWeight: "700",
    color: "#64748B",
    marginBottom: 20
  },
  subscriptionType: {
    marginBottom: 20
  },
  subscriptionInformation: {
    width: "100%",
    maxWidth: 370
  },
  subscriptionInformationHeader: {
    flexDirection: "row",
    marginBottom: 20
  },
  subscriptionInformationHeaderTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#1E293B",
    marginLeft: 8 
  },
  subscriptionInformationContent: {
    borderRadius: 8,
    padding: 30,
    backgroundColor: "#FFFFFF80"
  },
  goBackContainer: {
    width: 32,
    height: 32,
    borderRadius: 100,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  goBackIcon: {
    fontSize: 24, 
    color: "#0F172A",
  },
  subscriptionInformationContentHeader: {
    flexDirection: "row"
  },
  logo: {
    width: 44,
    height: 44,
    marginRight: 12
  },
  planName: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#000000"
  },
  subTitle: {
    fontFamily: "Inter",
    fontWeight: "500",
    color: "#4C7731"
  },
  checked: {
    width: 16,
    height: 16,
    marginRight: 12
  },
  benefits: {
    marginTop: 30
  },
  benefit: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8
  },
  benefitText: {
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: "400",
    color: "#000"
  },
  planAndPriceTitle: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#4C7731",
    textTransform: "uppercase",
    marginTop: 30
  },
  freeWeek: {
    fontFamily: "Inter",
    fontWeight: "700",
    color: "#8861A9",
    marginTop: 30
  },
  pricePeriodText: {
    fontFamily: "Inter",
    fontSize: 20, 
    fontWeight: "400", 
    color: "#474747",
    marginTop: 30
  },
  priceText: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000", 
    fontSize: 24
  },
  subscriptionImageContanier: {
    width: 676,
    height: 650,
    borderRadius: 55,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D9D9D933"
  },
  subscriptionImage: {
    maxWidth: "100%",
  },
  button: {
    backgroundColor: "#8861A9",
    marginTop: 20
  },
  buttonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    fontSize: 16,
    color: "#FFFFFF"
  },
  paymentTypeButtonRadio: {
    backgroundColor: "#FFFFFF80",
    borderWidth: 1,
    borderColor: "#CBD5E1",
    borderRadius: 8,
    marginTop: 8,
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  paymentTypeRadio: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth:  1,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentTypeRadioSelected: {
    height: 20,
    width: 20,
    borderRadius: 12,
    borderWidth:  6,
    borderColor: '#2A0066',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentTypeButtonRadioSelected: {
    backgroundColor: "#FFFFFF80",
    borderWidth: 1,
    borderColor: "#CBD5E1",
    borderRadius: 8,
    marginTop: 8,
    color: "#2A0066",
    paddingVertical: 16,
    paddingHorizontal: 20,
    flexDirection: "row",
    alignItems: "center"
  },
  buttonRadioContent: {
    marginLeft: 20, 
    flexDirection: "column", 
    alignItems: "flex-start", 
    justifyContent: "space-between", 
    flex: 1
  },
  buttonRadioTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#000"
  },
  buttonRadioSubtitle: {
    fontFamily: "Inter",
    fontWeight: "400", 
    color: "#000"
  }
});
// Customizable Area End
