import React from "react";
// Customizable Area Start
import { View, StyleSheet,TouchableOpacity, TouchableWithoutFeedback, Switch, Platform, Dimensions, ImageURISource } from "react-native";
import Popover from "../../../components/src/Popover.web";
import AppHeader from "../../../components/src/AppHeader.web";
import { Typography } from "@builder/component-library";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import FiltersIcon from '@mui/icons-material/TuneOutlined';
import ArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';
import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import { shopIcon, shopIconSelected, eatIcon, eatIconSelected, doIcon, doIconSelected, mePin } from './assets'
import ParkingIcon from '@mui/icons-material/LocalParkingOutlined';
import { Marker, Polygon } from "react-native-maps";
import Favourites from "../../favourites/src/Favourites";
import Filteritems from "../../filteritems/src/Filteritems";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch";
import StoreLocator from "../../storelocator/src/StoreLocator";
import Gallery from "../../storelocator/src/Gallery.web";
const config = require("../../../framework/src/config");

// Customizable Area End

const viewportHeight = Dimensions.get("window").height;
const viewportWidth = Dimensions.get("window").width;

import MapView from "react-native-maps";

import MapsController, { Props, configJSON } from "./MapsController";

export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
  }

  renderPopover() {
    return (
      <Popover 
        testID="popover"
        visible={this.state.popoverVisibility}
        icon={<LocationIcon sx={{fontSize: 24, color: "#8861A9"}} />}
        title="Share your location?"
        subTitle="Do you want to share your location? The link to your location will be copied to your clipboard."
        primaryButtonText="Share location"
        primaryButtonColor="#8861A9"
        secondaryButtonText="Cancel"
        {...this.popoverProps}
      />
    )
  }

  renderGallery() {
    return (
      this.state.galleryVisibility && (
        <Gallery 
          {...this.props} 
          photos={this.state.galleryPhotos} 
          onCloseGallery={this.handleCloseGallery} 
        />
      )
    )
  }

  renderFilters() {
    return (
      <View style={styles.filterContainer}>
        {
          this.state.categories.map(category => {
            return (
              <TouchableOpacity 
                key={category.id}
                testID={`btn${category.name}Filter`}
                style={this.state.filtering.includes(category.name) ? styles.filterButtonSelected : styles.filterButton} 
                onPress={() => this.onFiltering(category.name)}
              >
                <img src={config.baseURL + category.light_icon} style={styles.filterButtonIcon} />

                <Typography 
                  variant="base" 
                  style={this.state.filtering.includes(category.name) ? styles.filterButtonTextSelected : styles.filterButtonText}
                >
                  {category.name}
                </Typography>
              </TouchableOpacity>
            )
          })
        }
      </View>
    )
  }

  renderGeofence() {
    return (
      this.state.sideSection.type === "geofence" && (
        <View style={styles.geofenceContainer}>
          <View style={styles.geofenceHeader}>
            <Typography variant="base" style={styles.geofenceText}>5 Miles radius</Typography>
            <ArrowDownIcon sx={styles.geofenceHeaderIcon} />
          </View>

          <View style={styles.table}>
            <View style={styles.tr}>
              <View style={styles.tdLocation}>
                <Typography variant="sm" style={styles.theadText}>Location</Typography>
              </View>
              <View style={styles.tdUser}>
                <Typography variant="sm" style={styles.theadText}>User</Typography>
              </View>
              <View style={styles.tdTime}>
                <Typography variant="sm" style={styles.theadText}>Time</Typography>
              </View>
            </View>

            {
              Array.from({ length: 5 }).map((_, index) => {
                return (
                  <View key={index} style={styles.tr}>
                    <View style={styles.tdLocation}>
                      <Typography variant="sm" style={styles.tdText}>United state</Typography>
                    </View>
                    <View style={styles.tdUser}>
                      <Typography variant="sm" style={styles.tdText}>User name</Typography>
                    </View>
                    <View style={styles.tdTime}>
                      <Typography variant="sm" style={styles.tdText}>11:03:01</Typography>
                    </View>
                  </View>
                )
              })
            }
          </View>
        </View>
      )
    )
  }

  renderFavourites() {
    return (
      this.state.sideSection.type === 'favourites' && (
        <Favourites 
          {...this.props} 
          city_name={this.state.currentPosition.city_name}
          handleCloseFavourites={this.handleCloseSideSection} 
          handleOpenBusiness={this.handleOpenBusinessDetailsById}
        />
      )
    )
  }

  renderAdvancedSearch() {
    return (
      this.state.sideSection.type === "advanceSearch" && (
        <AdvancedSearch 
          {...this.props} 
          handleCloseAdvancedSearch={this.handleCloseSideSection}
          onOpenBusinessDetails={this.handleOpenBusinessDetails}
          onSearch={this.getStores}
          stores={this.state.stores}
          searchText={this.state.inputSearchText}
        />
      )
    )
  }

  renderFiltersSubCategories() {
    return (
      this.state.sideSection.type === "filters" && (
        <Filteritems 
          {...this.props} 
          selectedSubCategories={this.state.selectedSubCategories}
          handleCloseFilters={this.handleCloseSideSection} 
          onSaveFilters={this.handleSaveFilters}
        />
      )
    )
  }

  renderStoreLocator() {
    return (
      this.state.sideSection.type === "storeLocator" && (
        <StoreLocator 
          {...this.props} 
          user_type={this.state.user_type}
          store={this.state.selectedStore}
          onCloseStoreLocator={this.handleCloseSideSection}
          onOpenGallery={this.handleOpenGallery}
          showOnTheMap={this.showOnTheMap}
        />
      )
    )
  }

  renderLocateMeButton() {
    return (
      <TouchableOpacity 
        testID="btnLocateMe" 
        style={this.state.showLocateMe ? {...styles.locateMeButton, backgroundColor: "#8861A9" }: styles.locateMeButton} 
        onPress={this.handleLocateMe}
      >
        <MyLocationOutlinedIcon 
          sx={this.state.showLocateMe ? {...styles.locateMeIcon, color: "#FFF" }: styles.locateMeIcon} 
        />
      </TouchableOpacity>
    )
  }

  renderLocateMeMarker() {
    return (
      (this.state.showLocateMe && this.state.userLocation.coords.latitude && this.state.userLocation.coords.longitude) && (
        <Marker 
          testID="locateMe"
          coordinate={{
            latitude: this.state.userLocation.coords.latitude,
            longitude: this.state.userLocation.coords.longitude,
          }}
          icon={mePin}
        />
      )
    )
  }

  renderBussiness() {
    return (
      this.state.stores.map(store => {
        const iconUrl = store.business_category_type ? config.baseURL + store.business_category_type[0].data?.attributes.light_icon : "";

        return (
          <Marker 
            key={store.id}
            testID={`marker-${store.id}`}
            coordinate={{
              latitude: store.latitude,
              longitude: store.longitude,
            }}
            icon={{
              url: iconUrl,
              scaledSize: this.state.currentStoreShowMap === store.id ? new google.maps.Size(34, 34) : new google.maps.Size(24, 24)
            } as ImageURISource}
            onPress={() => this.handleOpenBusinessDetails(store)}
          />
        )
      })
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={styles.container}>
        {this.renderPopover()}

        {this.renderGallery()}

        <AppHeader {...this.props} onPressFavourites={this.state.user_type === "User" ? this.handleOpenFavourites : undefined} />

        <MapView
          ref={this.mapViewRef}
          // @ts-ignore
          options={{
            // mapId: "ed1e5d39a86cc40d",
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            showsUserLocation: true,
            showsMyLocationButton: true,
            restriction: this.state.mapLimits ? {
              latLngBounds: this.state.mapLimits,
              strictBounds: false,
            } : null,
            styles: configJSON.customMapStyle
          }}
          style={styles.map}
          initialRegion={{
            latitude: this.state.currentPosition.coords.latitude || 37.80025,
            longitude: this.state.currentPosition.coords.longitude || -122.4500,
            latitudeDelta: Math.abs((this.state.mapLimits?.north || 0) - (this.state.mapLimits?.south || 0)) * 0.3,
            longitudeDelta: Math.abs((this.state.mapLimits?.east || 0) - (this.state.mapLimits?.west || 0)) * 0.3,
          }}
        >
          <View style={styles.mapHeader}>
            {
              this.state.sideSection.isOpen ? (
                <View style={{ width: 380 }} />
              ) : (
                <TouchableWithoutFeedback testID="inputSearchContainer" onPress={this.handleFocusInputSearch}>
                  <View style={styles.inputSearchContainer}>
                    <SearchOutlinedIcon sx={styles.inputSearchIcon} />
                    <input 
                      id="inputSeach"
                      ref={this.inputSearchRef} 
                      style={this.isPlatformWeb() ? {...styles.inputSearch, borderWidth: 0, outlineStyle: "none" } : styles.inputSearch} 
                      placeholder="Search" 
                      value={this.state.inputSearchText}
                      {...this.inputSearchProps}
                    />
                  </View>
                </TouchableWithoutFeedback>
              )
            }
            <View style={styles.headerSection}>
              <TouchableOpacity testID="btnToggleFilters" onPress={this.handleToggleFilters} style={styles.headerFiltersButton}>
                <FiltersIcon sx={styles.headerFiltersIcon} />
              </TouchableOpacity>
            </View>

            <View style={styles.headerSection}>
              {this.renderFilters()}
            </View>

            <View style={this.state.filtering.includes("Parking") ? styles.headerSectionParkingSelected : styles.headerSectionParking}>
              <TouchableOpacity 
                testID="btnParkingFilter" 
                onPress={() => this.onFiltering("Parking")} 
                style={styles.parkingButton}
              >
                <ParkingIcon sx={this.state.filtering.includes("Parking") ? styles.parkingButtonTextSelected : styles.parkingButtonText} />
              </TouchableOpacity>
            </View>

            <View style={styles.headerSection}>
              <View style={styles.footerItem}>
                <Typography variant="base" style={styles.footerText}>Open today</Typography>
                <Switch 
                  testID="switchOpenTodayFilter"
                  style={styles.footerSwitch} 
                  thumbColor={"#FFF"}
                  {...Platform.select({web: {
                    activeThumbColor: "white"
                  }})}
                  trackColor={{
                    true: "#8861A9",
                    false: "#AAA"
                  }}
                  value={this.state.openToday}
                  {...this.switchOpenTodayFilterProps}
                />
              </View>
            </View>

            {
              this.state.user_type === "User" && (
                <View style={styles.headerSection}>
                  <View style={styles.footerItem}>
                    <Typography variant="base" style={styles.footerText}>Favorites</Typography>
                    <Switch 
                      testID="switchFavouritesFilter"
                      style={styles.footerSwitch} 
                      thumbColor={"#FFF"}
                      {...Platform.select({web: {
                        activeThumbColor: "white"
                      }})}
                      value={this.state.favourites} 
                      trackColor={{
                        true: "#8861A9",
                        false: "#AAA"
                      }} 
                      {...this.switchFavouritesFilterProps}
                    />
                  </View>
                </View>
              )
            }

            {
              this.state.user_type === "Business" && (
                <View style={styles.headerSectionParking}>
                  <TouchableOpacity 
                    testID="btnMyBusiness" 
                    onPress={() => this.handleShowMyBusiness()} 
                    style={styles.parkingButton}
                  >
                    <Typography variant="base" style={styles.footerText}>My Business</Typography>
                  </TouchableOpacity>
                </View>
            )}

          </View>

          <View style={[styles.sideSectionContainer, this.state.sideSection.isOpen && styles.sideSectionContainerAnimated]}>          

            {this.renderGeofence()}

            {this.renderFavourites()}

            {this.renderAdvancedSearch()}

            {this.renderFiltersSubCategories()}

            {this.renderStoreLocator()}
          </View>

          {this.renderLocateMeButton()} 

          {this.renderLocateMeMarker()}

          {this.renderBussiness()}

          {
            this.state.cityBoundary && (
              <Polygon
                coordinates={this.state.cityBoundary}
                strokeColor="#5600cc"
                fillColor="transparent"
                strokeWidth={4}
              />   
            )
          }
         
        </MapView>
      </View> 
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    position: "relative",
    flex: 1,
    width: "100%",
    backgroundColor: "#9BCE7B",
    alignItems: "center"
  },
  header: {
    paddingHorizontal: 40,
    paddingVertical: 16,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    zIndex: 99
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: 44,
    height: 44,
  },
  headerButton: {
    width: 44,
    height: 44,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4
  },
  headerButtonIcon: {
    fontSize: 24,
    color: "#0F172A"
  },
  filterContainer: {
    height: "100%",
    paddingVertical: 10,
    paddingHorizontal: 6,
    borderRadius: 50,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterButton: {
    // width: "100%",
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 6,
    paddingHorizontal: 12,
    paddingVertical: 8
  },
  filterButtonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#64748B",
    marginLeft: 6
  },
  filterButtonIcon: {
    fontSize: 24,
  },
  filterButtonTextSelected: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#8861A9",
    marginLeft: 6
  },
  filterButtonSelected: {
    height: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E1CCFF",
    borderRadius: 50,
    borderWidth: 1,
    borderColor: "#C399FF",
    marginHorizontal: 6,
    paddingHorizontal: 12,
    paddingVertical: 8
  },
  headerSectionParking: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginRight: 20,
  },
  headerSectionParkingSelected: {
    height: 60,
    backgroundColor: "#5500CC",
    borderRadius: 99,
    marginRight: 20,
  },
  parkingButton: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    alignItems: "center",
    justifyContent: "center",
  },
  parkingButtonText: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#64748B",
    fontSize: 22
  },
  parkingButtonTextSelected: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#FFF",
    fontSize: 22
  },
  map: {
    width: "100%",
    flex: 1,
    position: "relative"
  },
  mapHeader: {
    width: "100%",
    height: 40,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    paddingVertical: 25,
    paddingHorizontal: 40,
    flexDirection: "row",
    flexWrap: "wrap",
    ...Platform.select({
      web: {
        gap: 10
      }
    })
  },
  headerSection: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginRight: 20,
  },
  headerFiltersButton: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    alignItems: "center",
    justifyContent: "center"
  },
  headerFiltersIcon: {
    fontSize: 24,
    color: "#1E293B"
  },
  inputSearchContainer: {
    width: "100%",
    maxWidth: 410,
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginRight: 20,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 18
  },
  inputSearchIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  inputSearch: {
    flex: 1,
    marginLeft: 8,
    color: "#1E293B",
    fontSize: 14
  },
  footerText: {
    fontWeight: "400",
    fontFamily: "Inter",
    color: "#0F172A"
  },
  footer: {
    maxWidth: 1100,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  footerItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingVertical: 16,
    paddingHorizontal: 24
  },
  footerSwitch: {
    marginLeft: 12,
  },
  locateMeButton: {
    position: "absolute",
    bottom: 120,
    right: 10,
    width: 42,
    height: 42,
    borderRadius: 58,
    backgroundColor: "#FFF",
    alignItems: "center",
    justifyContent: "center"
  },
  locateMeIcon: {
    fontSize: 22,
    color: "#475569",
  },
  sideSectionContainer: {
    width: 0,
    maxWidth: 0,
    height: "100%",
    backgroundColor: "#9BCE7B",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    opacity: 0,
    ...Platform.select({
      web: {
        transition: 'width 0.3s ease, max-width 0.3s ease, opacity 0.3s ease',

      }
    })
  },
  sideSectionContainerAnimated: {
    opacity: 1,
    width: "100%",
    maxWidth: 375
  },
  geofenceContainer: {},
  geofenceHeader: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginTop: 27
  },
  geofenceText: {
    fontFamily: "Inter",
    color: "#000000",
    fontWeight: "500",
    marginRight: 12
  },
  geofenceHeaderIcon: {
    fontSize: 20,
    color: "#000000"
  },
  table: {
    paddingHorizontal: 8
  },
  theadText: {
    fontFamily: "Inter",
    color: "#64748B",
    fontWeight: "700"
  },
  tr: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 14,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E2E8F0",
  },
  tdLocation: {
    width: 150
  },
  tdUser: {
    width: 130
  },
  tdTime: {
    width: 80
  },
  tdText: {
    fontFamily: "Inter",
    fontWeight: "400",
    color: "#0F172A"
  }
});
// Customizable Area End
