import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Pressable,
} from "react-native";
import { Typography  } from "@builder/component-library";
import HeartFilledIcon from '@mui/icons-material/FavoriteOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { defaultBusinessImage } from './assets';
const config = require("../../../framework/src/config");
// Customizable Area End

import { Props, configJSON } from "./FavouritesController";

import FavouritesController from "./FavouritesController";

export default class Favourites extends FavouritesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      //Merge Engine DefaultContainer
        <>
        {/* Customizable Area Start */}
        <View style={styles.favouriteContainerHeader}>
          <Typography style={styles.favouriteContainerHeaderTitle} variant="xl">Favorites</Typography>

          <View style={styles.favouriteContainerHeaderActions}>

            <TouchableOpacity testID="btnCloseFavourites" onPress={this.props.handleCloseFavourites} >  {/* onPress={this.handleCloseSideSection} */}
              <CloseOutlinedIcon sx={styles.favouriteContainerHeaderIcon} />
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.inputFavoritesSearchContainer}>
          <SearchOutlinedIcon sx={styles.inputFavoritesSearchIcon} />
          <input 
            id="inputSeachFavorites"
            // ref={this.inputSearchRef} 
            style={this.isPlatformWeb() ? {...styles.inputFavoriteSearch, borderWidth: 0, outlineStyle: "none" } : styles.inputFavoriteSearch} 
            placeholder="Search Favorites" 
            value={this.state.inputSearchFavoriteText}
            {...this.inputSearchFavoriteProps}
          />
          {
            this.state.inputSearchFavoriteText.length > 0 && (
              <TouchableOpacity testID="btnClearInput" onPress={this.handleClearFavoritesInput}>
                <CloseOutlinedIcon sx={styles.favoritesContainerHeaderIcon} />
              </TouchableOpacity>
            )
          }
        </View>

        <ScrollView style={styles.container}>
          <View style={styles.header}>
            <Typography style={styles.title} variant="xs">{this.state.favourites.length.toString()} Businesses</Typography>
          </View>

          {
            this.state.loading ? (
              <ActivityIndicator style={{ marginTop: 30 }} color="#FFF" />
            ) : this.state.filteredFavourites.map((favourite) => {
              return (
                <Pressable 
                  onPress={() => this.props.handleOpenBusiness(favourite.attributes.business_account_details.id)} 
                  testID={`btn-favourite-${favourite.id}`} 
                  key={favourite.id} 
                  style={styles.itemContainer}
                >
                  <View style={styles.itemInfo}>
                    <img 
                      style={styles.businessImage}
                      src={favourite.attributes.profile_image[0]?.url ? config.baseURL + favourite.attributes.profile_image[0].url : defaultBusinessImage} 
                    />
                    <View>
                      <Typography style={styles.businessName} variant="base">{favourite.attributes.business_account_details.user_name}</Typography>

                      <Typography style={styles.openText} variant="xs">{favourite.attributes.show_open || "Closed"}</Typography>
                    </View>
                  </View>
                  
                  <TouchableOpacity testID={`btnDeleteFavourite-${favourite.id}`} onPress={() => this.deleteFavouritesApiCall(favourite.id)}>
                    <HeartFilledIcon sx={styles.heartIconFilled} />
                  </TouchableOpacity>
                </Pressable>
              )
            })
          }
        </ScrollView>
        {/* Customizable Area End */}
        </>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 30,
  },
  favouriteContainerHeader: {
    flexDirection: "row",
    marginTop: 36,
    paddingHorizontal: 24,
    alignItems: "center"
  },
  inputFavoritesSearchContainer: {
    height: 60,
    backgroundColor: "#FFF",
    borderRadius: 99,
    marginHorizontal: 20,
    marginTop: 26,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 22,
    paddingVertical: 18
  },
  inputFavoritesSearchIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  inputFavoriteSearch: {
    flex: 1,
    marginLeft: 8,
    color: "#1E293B",
    fontSize: 14
  },
  favoritesContainerHeaderIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  favouriteContainerHeaderTitle: {
    fontWeight: "700",
    fontFamily: "Inter",
    color: "#1E293B"
  },
  favouriteContainerHeaderActions: {
    flexDirection: "row",
    marginLeft: "auto"
  },
  favouriteContainerHeaderActionButton: {
    marginRight: 8
  },
  favouriteContainerHeaderIcon: {
    fontSize: 24,
    color: "#1E293B",
  },
  header: {
    paddingHorizontal: 24,
    paddingVertical: 8,
    borderBottomColor: "#E2E8F0",
    borderBottomWidth: 1
  },
  title: {
    fontWeight: "700",
    color: "#64748B",
  },
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomColor: "#E2E8F0",
    borderBottomWidth: 1
  },
  itemInfo: {
    flexDirection: "row",
    alignItems: "center"
  },
  businessImage: {
    width: 44,
    height: 44,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: "#F0E5FF",
    borderStyle: "solid",
    marginRight: 16
  },
  businessName: {
    fontWeight: "400",
    color: "#0F172A",
  },
  openText: {
    fontWeight: "400",
    color: "#5F05FF",
  },
  heartIconFilled: {
    fontSize: 24,
    color: "#DC2626"
  },
});
// Customizable Area End
