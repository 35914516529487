import React from 'react'
import { Pressable, StyleSheet, Text, TouchableOpacity, View } from 'react-native'

import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { runEngine } from "../../framework/src/RunEngine";

import LocationIcon from '@mui/icons-material/LocationOnOutlined';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import eventEmitter from './EventEmitter';

const logo = require('./logo.png').default;

interface Props {
  navigation: any;
  id: string;
  onPressFavourites?: () => void;
}

export interface ApiCallInterface {
  method: string;
  contentType: string;
  body?: Object;
  endPoint: string;
}


interface S { 
  visible: boolean;
  notificationCount : number;
}

interface SS { }

class AppHeader extends BlockComponent<Props, S, SS> {
  static instance: AppHeader;
  apiNotificationCallId: string = "";
  constructor(props: Props) {
    super(props);
    AppHeader.instance = this;
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      visible: false,
      notificationCount : 0,
    }
  runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  goToUserProfile = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToChooseCity = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationLandingPageMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      prevScreen: window.location.pathname.replace("/", "")
    });
    this.send(msg);
  }

  goToHome = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id
      && this.apiNotificationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      if (responseJson.data) {
        this.setState({ notificationCount: responseJson.data.filter((notification: any) => !notification.attributes.is_read).length });
      }
    }
    // Customizable Area End
  }

  apiCall = async (ApiCallData: ApiCallInterface) => {
    const { contentType, method, endPoint } = ApiCallData;
    const header = {
      "Content-Type": contentType,
      "token": localStorage.getItem('token')
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getNotification = async() => {
    this.apiNotificationCallId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: "bx_block_notifications/all_notifications",
    })
  }

  async componentDidMount() {
    this.getNotification();
    eventEmitter.on('notificationUpdate', (notificationCount: number) => {
      console.log("Event on")
      this.setState({ notificationCount });
    });
  }

  render() {
    return (
      <View style={styles.header}>
        <Pressable>
          <img src={logo} style={styles.logo} />
        </Pressable>

        <Emailnotifications2 {...this.props} visible={this.state.visible} />

        <View style={styles.headerActions}>
          {
            this.props.onPressFavourites  && (
              <TouchableOpacity testID="btnFavourite" style={styles.headerButton} onPress={this.props.onPressFavourites}>
                <FavoriteBorderIcon sx={styles.headerButtonIcon} />
              </TouchableOpacity>
            )
          }

          <TouchableOpacity testID="btnChooseCity" style={styles.headerButton} onPress={() => this.goToChooseCity()}>
            <LocationIcon sx={styles.headerButtonIcon} />
          </TouchableOpacity>

          <TouchableOpacity testID="btnNotifications" style={styles.headerButton} onPress={() => { this.setState({  visible: !this.state.visible }) }}>
            <NotificationsNoneIcon sx={styles.headerButtonIcon} />
            {this.state.notificationCount > 0 && (
            <View style={styles.notificationBadge}>
              <Text style={styles.badgeText}>{this.state.notificationCount}</Text>
            </View>
          )}
          </TouchableOpacity>

          <TouchableOpacity testID="btnUserProfile" style={styles.headerButton} onPress={() => this.goToUserProfile()}>
            <PersonOutlineOutlinedIcon sx={styles.headerButtonIcon} />
          </TouchableOpacity>
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 40,
    paddingVertical: 16,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    shadowColor: "#000000",
    shadowOffset: { width: 0, height: 4 },
    shadowOpacity: 0.1,
    shadowRadius: 12,
    zIndex: 10,
  },
  headerActions: {
    flexDirection: "row",
    alignItems: "center",
  },
  logo: {
    width: 44,
    height: 44,
  },
  headerButton: {
    width: 44,
    height: 44,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 4
  },
  headerButtonIcon: { 
    fontSize: 24, 
    color: "#0F172A" 
  },
  notificationBadge: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: 'red',
    borderRadius: 10,
    width: 15,
    height: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
  },
})

export default AppHeader;
