import React from "react";
// Customizable Area Start
import {
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import { Typography } from '@builder/component-library';
import ArrowLeftIcon from '@mui/icons-material/ArrowBackOutlined';
import PlanCard from "../../../components/src/PlanCard";
import { subscriptionImage } from "./assets";
import Popover from "../../../components/src/Popover.web";

// Customizable Area End

import SubscriptionDetailsController, {
  configJSON,
} from "./SubscriptionDetailsController";

export default class SubscriptionDetails extends SubscriptionDetailsController {
  render() {
    return (
      // Customizable Area Start
      <View style={styles.subscriptionDetailsScreen}>
        <ScrollView>
          <View style={styles.subscriptionDetailsMain}>
            <View style={styles.subscriptionDetailsContent}>
              <View style={styles.subscriptionDetailsInformation}>
                <View style={styles.subscriptionDetailsInformationHeader}>
                  <TouchableOpacity style={styles.goBackContainer} testID="btnGoBack" onPress={() => this.props.navigation.goBack()}>
                    <ArrowLeftIcon sx={styles.goBackIcon} />
                  </TouchableOpacity>
                  <Typography variant="xl" style={styles.subscriptionDetailsInformationHeaderTitle}>Subscription information</Typography>
                </View>

                <Typography variant="xs" style={styles.subscriptionDetailsInformationText}>CURRENT PLAN</Typography>
                
                <PlanCard
                  testID="planCard"
                  planName={this.state.plan.attributes.name}
                  planDescription={this.state.plan.attributes.sub_title}
                  benefits={this.state.plan.attributes.description.split(',')}
                  isFreetrial={this.state.isFreeTrial}
                  monthlyPrice={this.state.plan.attributes.monthly_price}
                  yearlyPrice={this.state.plan.attributes.yearly_price}
                  currentPlan
                  planType={this.state.plan.attributes.plan_type}
                  buttonActionText={this.state.userType === "user" ? "Cancel subscription": "Change subscription"}
                  {...this.btnChangeSubscription}
                />
              </View>

              <View style={styles.subscriptionDetailsImageContanier}>
                <img src={subscriptionImage} style={styles.subscriptionDetailsImage} />
              </View>
            </View>
          </View>
        </ScrollView>
        {this.state.isPopoverVisible && (
        <Popover
          testID="changeSubscriptionPopover"
          visible={this.state.isPopoverVisible}
          title={this.state.userType === "user" ? configJSON.confirmCancelPlanPopoverTitle : configJSON.confirmChangePlanPopoverTitle}
          subTitle={this.state.userType === "user" ? configJSON.confirmCancelPlanPopoverSubTitle : configJSON.confirmChangePlanPopoverSubTitle.replace("<Subscription name>", this.state.plan.attributes.name).replace("<Subscription end date>", this.formatDate(this.state.plan.attributes.expiration_date))}
          primaryButtonText={this.state.userType === "user" ? configJSON.confirmCancelPlanPopoverPrimaryButtonText : configJSON.confirmChangePlanPopoverPrimaryButtonText}
          primaryButtonColor={configJSON.confirmChangePlanPopoverPrimaryButtonColor}
          secondaryButtonText={configJSON.confirmChangePlanPopoverSecondaryButtonText}
          {...this.popoverProps}
        />
      )}
      </View>
      // Customizable Area End
    );
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  subscriptionDetailsScreen: {
    width: "100%",
    height: "100%", 
    flex: 1,
    backgroundColor: "#9BCE7B",
  },
  subscriptionDetailsMain: {
    flex: 1, 
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: "auto",
    width: "100%", 
    height: "100%",
    maxWidth: 1200,
    padding: 40,
  },
  subscriptionDetailsContent: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  subscriptionDetailsInformation: {
    width: "100%",
    maxWidth: 370
  },
  subscriptionDetailsInformationHeader: {
    flexDirection: "row",
    marginBottom: 20
  },
  subscriptionDetailsInformationHeaderTitle: {
    fontFamily: "Inter",
    fontWeight: "700", 
    color: "#1E293B",
    marginLeft: 8 
  },
  subscriptionDetailsInformationText: {
    fontWeight: "700",
    color: "#64748B",
    marginBottom: 20
  },
  goBackContainer: {
    width: 32,
    height: 32,
    borderRadius: 100,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFF",
  },
  goBackIcon: {
    color: "#0F172A",
    fontSize: 24, 
  },
  subscriptionDetailsImageContanier: {
    width: 676,
    height: 650,
    borderRadius: 55,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#D9D9D933"
  },
  subscriptionDetailsImage: {
    maxWidth: "100%",
  },
  // Customizable Area End
});

// Customizable Area Start
// Customizable Area End
