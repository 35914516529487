import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { removeStorageData } from "../../../framework/src/Utilities";


type Plan = {
  id: string;
  type: string;
  attributes: {
    name: string;
    yearly_price: string;
    monthly_price: string;
    description: string;
    sub_title: string;
    subscription_type: "yearly" | "monthly" | "weekly";
    valid_up_to: string;
    expired: boolean;
    image_link: string | null,
    subscribed: boolean;
    plan_type: "Basic" | "Premium" | "Founder" | "Event" | "User Plan";
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  plans: Array<Plan>;
  userPlan: Plan;
  userType: "User" | "Business";
  hasSubscription: boolean;
  isFreeTrailsClaimed: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListCallId: any;
  getBusinessSubscriptionsCallId: string = "";
  getUserInformationCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      plans: [],
      userType: "Business",
      userPlan: {
        id: "",
        type: "",
        attributes: {
          name: "",
          yearly_price: "",
          monthly_price: "",
          description: "",
          sub_title: "",
          subscription_type: "yearly",
          valid_up_to: "",
          expired: false,
          image_link:"",
          subscribed: false,
          plan_type: "User Plan",
        }
      },
      hasSubscription: false,
      isFreeTrailsClaimed: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token, plans: [] }, () => {
        this.getUserInformation();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson && responseJson.data) {
        if (apiRequestCallId === this.getUserInformationCallId) {
          if (responseJson.data.attributes.type === "User") {
            this.fetchSubscriptionsList();
          } else {
            this.fetchBusinessSubscriptions();
          }
          const hasSubscription = responseJson.data.attributes.subscriptions.length > 0;

          this.setState({
            userType: responseJson.data.attributes.type,
            hasSubscription: hasSubscription,
            isFreeTrailsClaimed: responseJson.data.attributes.isFreeTrailsClaimed
          })
        } else if (apiRequestCallId === this.getListCallId) {
          const userPlan = responseJson.data as Plan[];

          this.setState({
            userPlan: userPlan[0]
          })
        } else if (apiRequestCallId === this.getBusinessSubscriptionsCallId) {
          const plans = responseJson.data as Plan[];
          const plansSorted = [...plans].sort((a, b) => Number(a.id) - Number(b.id));

          this.setState({
            plans: plansSorted
          })
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  btnGoBackProps = {
    onPress: () => this.goToHome()
  }

  goToHome() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationUserProfileMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  goToBillingInformation(plan: Plan) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Subscriptionbilling2");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
   
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { plan, isFreetrial: !this.state.isFreeTrailsClaimed && this.state.userType === "User"});
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    removeStorageData("selectedPlan");
    removeStorageData("selectedSubscriptionId");

    this.send(msg);
  }

  getUserInformation = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserInformationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserInformationEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchBusinessSubscriptions = () => {
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBusinessSubscriptionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBusinessSubscriptions
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
