import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import eventEmitter from "../../../components/src/EventEmitter";
const config = require("../../../framework/src/config");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  visible: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  notifications: Array<{
    id: number;
    headings: string;
    contents: string;
    is_read: boolean;
    account: {
      id: number;
      city_name: string;
      latitude: number;
      longitude: number;
      profile_image: string;
    }
  }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Emailnotifications2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  notificationsApiCallId: string = "";
  deletenotificationApiCallId: string = "";
  readnotificationApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: "",
      notifications: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));

      if (token) {
        this.setState({
          token
        }, () => {
          this.getNotifications();
        })
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  selectedNotification(notificationId: number, businessId: number, city: { city_name: string, latitude: number, longitude: number}) {
    this.readNotification(notificationId);

    if ((city.latitude === 0 && city.longitude === 0) || (city.latitude === null && city.longitude === null)) return;

    const message: Message = new Message(
      getName(MessageEnum.NavigationMapsMessage)
    );
    
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      latitude: city.latitude,
      longitude: city.longitude,
      city_name: city.city_name,
      selectedStoreId: businessId
    });
    
    this.send(message);
  }

  private handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.notificationsApiCallId ) {
      if (responseJson && responseJson.data) {
        this.setState({
          notifications: responseJson.data.map((notification: any) => {
            return {
              ...notification.attributes,
              account: {
                ...notification.attributes.account,
                profile_image: notification.attributes.account.profile_image[0] ? config.baseURL + notification.attributes.account.profile_image[0].url : ""
              }
            }
          })
        }, () => {
          eventEmitter.emit('notificationUpdate', responseJson.data.filter((notification: any) => !notification.attributes.is_read).length, []);
        })
      }
      else if (responseJson && responseJson.errors) {
        eventEmitter.emit('notificationUpdate', 0, []);
      }
    }
    else if (apiRequestCallId === this.deletenotificationApiCallId && responseJson && responseJson.message) {
      this.getNotifications();
    }
    else if (apiRequestCallId === this.readnotificationApiCallId && responseJson) {
      this.getNotifications();
    }
  }


  clearAllNotifications = () => {
    this.state.notifications.forEach(notification => {
      if (!notification.is_read) {
        this.readNotification(notification.id)
      }
    })
  }

  getNotifications = async () => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    
    const getNotificationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationsApiCallId = getNotificationsMsg.messageId;

    getNotificationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getNotificationsAPiEndPoint}`
    );

    getNotificationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getNotificationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(getNotificationsMsg.id, getNotificationsMsg);
  }

  readNotification = async (notificationId: number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };
    
    const readNotificationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.readnotificationApiCallId = readNotificationMsg.messageId;

    readNotificationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.readNotificationAPiEndPoint}/${notificationId}`
    );

    readNotificationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    readNotificationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(readNotificationMsg.id, readNotificationMsg);
  }

  deleteNotification = async (notificationId: number) => {
    const headers = {
      "Content-Type": configJSON.exampleApiContentType,
      token: this.state.token
    };

    this.setState({
      notifications: this.state.notifications.filter(notification => notification.id !== notificationId)
    })
    
    const deleteNotificationMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deletenotificationApiCallId = deleteNotificationMsg.messageId;

    deleteNotificationMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteNotificationAPiEndPoint}/${notificationId}`
    );

    deleteNotificationMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteNotificationMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(deleteNotificationMsg.id, deleteNotificationMsg);
  }
  // Customizable Area End
}
