import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
type BusinessQuestion = {
  id: number;
  question: string;
  question_type: "Dropdown" | "Single line";
  has_custome_options: boolean;
  question_options: Array<string | number>;
  options?: Array<{
    id: number;
    name: string;
  }>;
}
import { ValueType } from "react-select";
import { createRef } from "react";
import { ScrollView } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  loading: boolean;
  businessId: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm_password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  alertVisibility: boolean;
  alertMessage: string;
  selectedAccountType: "User" | "Business";
  accountType: null | "User" | "Business";
  businessName: string;
  businessCountry: ValueType<{ label: string; value: string; }, false>;
  businessState: ValueType<{ label: string; value: string; }, false>;
  businessCity: ValueType<{ label: string; value: string; }, false>;
  countriesName: Array<{ label: string, value: string }>;
  statesName: Array<{ label: string, value: string }>;
  citiesName: Array<{ label: string, value: string }>;
  businessStreet: string;
  acceptTermsAndConditions: boolean;
  passwordValidations: {
    uppercase: boolean;
    lowercase: boolean;
    number: boolean;
    length: boolean;
    match: boolean;
  };
  screenStep: "SelectUserType" | "SignUpFrom" | "BusinessQuestions" | "ReviewOnGoing";
  reviewOnGoingTitle: string;
  reviewOnGoingText: string;
  businessQuestionsList: Array<BusinessQuestion>;
  businessAnswersList: Array<{
    question_id: number;
    answer: string;
  }>;
  businessAnswersDropdown: Array<{
    question_id: number;
    answer: ValueType<{ label: string; value: string; }, false>;
  }>;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  isToggling: boolean;
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  businessValidationApiCallId: string = "";
  validationApiCallId: string = "";
  businessQuestionApiCallId: string = "";
  businessAnswersApiCallId: string = "";
  countriesNameApiCallId: string = "";
  statesNameApiCallId: string = "";
  citiesNameApiCallId: string = "";
  apiSignInCallId: string = "";
  apiGetUserInfoCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  errorPasswordUppercase: string;
  errorPasswordLowercase: string;
  errorPasswordNumber: string;
  errorPasswordLength: string;
  errorPasswordMatch: string;
  errorAcceptTermsAndConditions: string;
  welcomeLabel: string;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  emailAccountRegistrationScrollRef = createRef<ScrollView>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      loading: false,
      businessId: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirm_password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      acceptTermsAndConditions: false,
      countryCodeSelected: "",
      alertVisibility: false,
      alertMessage: "",
      phone: "",
      passwordValidations: {
        uppercase: false,
        lowercase: false,
        number: false,
        length: false,
        match: false
      },
      selectedAccountType: "User",
      accountType: null,
      businessName: "",
      businessCountry: { label: "", value: ""},
      businessState: { label: "", value: ""},
      businessCity: { label: "", value: ""},
      countriesName: [],
      statesName: [],
      citiesName: [],
      businessStreet: "",
      screenStep: "SelectUserType",
      reviewOnGoingTitle: "",
      reviewOnGoingText: "",
      businessQuestionsList: [],
      businessAnswersList: [],
      businessAnswersDropdown: []
      // Customizable Area End
    };

    // Customizable Area Start
    this.isToggling = false;
    this.arrayholder = [];
    this.passwordReg = new RegExp("^(?=.*[A-Z])(?=.*[#!@$&*?<>',\\[\\]}{=\\-)(^%`~+.:;_])(?=.*[0-9])(?=.*[a-z]).{8,}$");
    this.emailReg = new RegExp("^[a-zA-Z0-9.!\\#$%&‘*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.welcomeLabel = configJSON.welcomeLabel;
    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    this.errorPasswordUppercase = configJSON.errorPasswordUppercase
    this.errorPasswordLowercase = configJSON.errorPasswordLowercase
    this.errorPasswordNumber = configJSON.errorPasswordNumber
    this.errorPasswordLength = configJSON.errorPasswordLength
    this.errorPasswordMatch = configJSON.errorPasswordMatch

    this.errorAcceptTermsAndConditions = configJSON.errorAcceptTermsAndConditions
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.isApiResponse(message)) {
      const apiRequestCallId = this.getApiRequestCallId(message);
      const responseJson = this.getResponseData(message);
      const errorResponse = this.getErrorResponse(message);
  
      if (apiRequestCallId && responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson, errorResponse);
      }
    } else if (this.isNavigationPayload(message)) {
      this.handleNavigationPayload(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  private isApiResponse(message: Message) {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }
  
  private getApiRequestCallId(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  }
  
  private getResponseData(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  }
  
  private getErrorResponse(message: Message) {
    return message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  }
  
  private handleApiResponse(apiRequestCallId: string, responseJson: any, errorResponse: any) {
    this.setState({
      loading: false
    });

    if (apiRequestCallId === this.validationApiCallId) {
      this.handleValidationApiResponse(responseJson);
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      this.handleCreateAccountApiResponse(responseJson, errorResponse);
    } else if (apiRequestCallId === this.businessQuestionApiCallId) {
      this.handleBusinessQuestionsApiResponse(responseJson);
    } else if (apiRequestCallId === this.businessAnswersApiCallId) {
      this.handleBusinessAnswersApiResponse(responseJson);
    } else if (apiRequestCallId === this.countriesNameApiCallId) {
      this.handleCountriesNameApiResponse(responseJson);
    } else if (apiRequestCallId === this.statesNameApiCallId) {
      this.handleStatesNameApiResponse(responseJson);
    } else if (apiRequestCallId === this.citiesNameApiCallId) {
      this.handleCitiesNameApiResponse(responseJson);
    } else if (apiRequestCallId === this.apiSignInCallId) {
      this.saveLoginData(responseJson)
    } else if (apiRequestCallId === this.apiGetUserInfoCallId) {
      this.handleUserInformationResponse(responseJson);
    } else if (apiRequestCallId === this.businessValidationApiCallId) {
      this.handleBusinessValidationApiResponse(responseJson)
    }
  }


  saveLoginData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
    localStorage.setItem("token", responseJson.meta.token);
    localStorage.setItem("userId", responseJson.meta.id);
    this.getUserInfo(responseJson.meta.token)
  }

  private handleUserInformationResponse(responseJson: any) {
    if (responseJson && responseJson.data) {
      const { attributes } = responseJson.data;
      const hasSubscription = responseJson.data.attributes.subscriptions.length > 0; 
      let msg: Message;
      
      if (attributes.type === "User") {
        if (hasSubscription) {
          msg = new Message(getName(MessageEnum.NavigationLandingPageMessage));
        } else {
          msg = new Message(
            getName(responseJson.data.attributes.isFreeTrailsClaimed ? MessageEnum.NavigationCustomisableUserSubscriptionsMessage : MessageEnum.NavigationFreeTrialMessage)
          );
        }
      } else {
        msg = new Message(
          getName(hasSubscription ? MessageEnum.NavigationUserProfileMessage : MessageEnum.NavigationCustomisableUserSubscriptionsMessage)
        );
      }

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }

  private handleCountriesNameApiResponse(responseJson: any) {
    this.setState({
      countriesName: Object.entries<string>(responseJson.countries).map(([key, value]) => ({
        label: value,
        value: value
      }))
    })
  }

  private handleStatesNameApiResponse(responseJson: any) {
    this.setState({
      statesName: responseJson.states.map((state: string) => {
        return {
          label: state,
          value: state
        }
      })
    })
  }

  private handleCitiesNameApiResponse(responseJson: any) {
    this.setState({
      citiesName: responseJson.cities.map((city: string) => {
        return {
          label: city,
          value: city
        }
      })
    })
  }
  
  private handleValidationApiResponse(responseJson: any) {
    this.arrayholder = responseJson.data;
  
    if (this.arrayholder?.length) {
      const regexData = this.arrayholder[0];
  
      this.passwordReg = new RegExp(regexData.password_validation_regexp || '');
      this.emailReg = new RegExp(regexData.email_validation_regexp || '');
  
      this.setState({
        passwordHelperText: regexData.password_validation_rules || ''
      });
    }
  }

  private handleBusinessValidationApiResponse(responseJson: any) {
    if (responseJson.status) {
      this.getBusinessQuestions();
      this.setState({ screenStep: "BusinessQuestions", alertVisibility: false });
    } else {
      this.showAlert("Error", responseJson.errors)
    }
  }
  
  private handleCreateAccountApiResponse(responseJson: any, errorResponse: any) {
    if (!responseJson.errors && responseJson.account?.meta?.token) {
      this.handleSuccessfulAccountCreation(responseJson);
    } else {
      // Actived account
      this.setState({
        screenStep: "SignUpFrom"
      })

      if (responseJson.message && responseJson.message === "Redirecting to subscription screen") {
        this.signIn();
        return;
      }

      if (typeof responseJson.errors === 'string') {
        this.showAlert('Error', responseJson.errors);
        return;
      }
  
      if (typeof responseJson.message === 'string') {
        this.showAlert('Error', responseJson.message);
        return;
      }

      this.parseApiErrorResponse(responseJson);
      this.parseApiCatchErrorResponse(errorResponse);
    }
  }
  
  handleSuccessfulAccountCreation(responseJson: any) {
    if (this.state.selectedAccountType === "Business") {
      this.setState({
        otpAuthToken: responseJson.account.meta.token,
        businessId: responseJson.account.data.id
      }, () => this.sendBusinessAnswers());
    } else {
      this.setState({
        screenStep: "ReviewOnGoing",
        reviewOnGoingTitle: "Check your email",
        reviewOnGoingText: "We have sent a notification to your registered email id. Please validate."
      });
    }
  }
  
  private handleBusinessQuestionsApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      const businessQuestions = responseJson as Array<BusinessQuestion>;
      const sortedBusinessQuestions = [...businessQuestions].sort((a, b) => a.id - b.id);

      this.setState({
        businessQuestionsList: sortedBusinessQuestions
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  
  private handleBusinessAnswersApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      this.setState({
        screenStep: "ReviewOnGoing",
        reviewOnGoingTitle: "Account review ongoing",
        reviewOnGoingText: "Your profile is being reviewed. We will get back to you shortly."
      });
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }
  
  private isNavigationPayload(message: Message) {
    return getName(MessageEnum.NavigationPayLoadMessage) === message.id;
  }
  
  private handleNavigationPayload(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
  
    if (otpAuthTkn) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  
    const navigationData = message.getData(getName(MessageEnum.NavigationUserRoleMessage));
    if (navigationData) {
      this.handleUserRoleNavigation(navigationData);
    }
  }
  
  private handleUserRoleNavigation({ userRole, userData, acceptTermsAndConditions }: any) {
    if (userRole) {
      this.setState({
        accountType: userRole,
        selectedAccountType: userRole,
        screenStep: "SignUpFrom",
        acceptTermsAndConditions: acceptTermsAndConditions
      });
    }
  
    if (userData) {
      this.setUserData(userRole, userData);
    }
  }
  
  private setUserData(userRole: string, userData: any) {
    if (userRole === "User") {
      this.setState({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        password: userData.password,
        confirm_password: userData.confirm_password
      });
    } else {
      this.setState({
        businessCountry: userData.businessCountry,
        businessState: userData.businessState,
        businessCity: userData.businessCity,
        businessStreet: userData.businessStreet,
        businessName: userData.businessName,
        email: userData.email,
        password: userData.password,
        confirm_password: userData.confirm_password
      });
      this.getCountriesName();
      
      if (userData.businessCountry.value) {
        this.getStatesName(userData.businessCountry.value)
      }

      if (userData.businessState.value) {
        this.getCitiesName(userData.businessState.value)
      }
    }

    this.setState({
      passwordValidations: {
        uppercase: /[A-Z]/.test(userData.password),
        lowercase: /[a-z]/.test(userData.password),
        number: /\d/.test(userData.password),
        length: userData.password.length >= 8,
        match: userData.password === userData.confirm_password
      },
    })
  }

  navigateUserToPrivacyOrTerms(type: "PrivacyPolicy" | "TermsAndCondition", msg: Message) {
    const termsAndConditionType = this.state.selectedAccountType === "User" ? "users" : "business";

    msg.addData(getName(MessageEnum.NavigationPayLoadMessage), {
      type,
      termsAndConditionType,
      acceptTermsAndConditions: this.state.acceptTermsAndConditions,
      data: termsAndConditionType === "users" ? {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        password: this.state.password,
      } : {
        businessName: this.state.businessName,
        email: this.state.email,
        password: this.state.password,
        businessCountry: this.state.businessCountry,
        businessState: this.state.businessState,
        businessCity: this.state.businessCity,
        businessStreet: this.state.businessStreet,
      } 
    });

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }
  
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    
    this.navigateUserToPrivacyOrTerms("PrivacyPolicy", msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    this.navigateUserToPrivacyOrTerms("TermsAndCondition", msg);
  }

  showAlert(alertType: string, alertMessage: string) {
    if (this.emailAccountRegistrationScrollRef.current) {
      this.emailAccountRegistrationScrollRef.current.scrollTo({
        y: 0,
        animated: true
      })
    }

    this.setState({
      alertVisibility: true,
      alertMessage: alertMessage
    })
  }

  goToLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.trim().length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  businessValidation(): boolean {
    if (this.state.acceptTermsAndConditions === false) {
      this.showAlert(configJSON.errorTitle, this.errorAcceptTermsAndConditions);
      return false;
    }

    if (!this.validateSignUpFormBusinees()) {
      return false;
    }

    if (!this.validateSignUpEmailAndPassword()) return false;

    this.setState({
      alertVisibility: false,
      alertMessage: "",
      loading: true,
    })

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.businessValidationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validationEmailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        email: this.state.email
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  createAccount(): boolean {
    if (
      this.state.accountType === 'User' && (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password))
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.validateSignUpFormBusinees()) {
      return false;
    }

    if (!this.validateSignUpEmailAndPassword()) return false;

    this.setState({
      alertVisibility: false,
      alertMessage: "",
      loading: true,
    })

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = this.state.accountType === "User" ? {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
    } : {
      user_name: this.state.businessName,
      email: this.state.email,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
      country: this.state.businessCountry?.value,
      state: this.state.businessState?.value,
      city: this.state.businessCity?.value,
      street: this.state.businessStreet
    };

    const data = {
      type: this.state.accountType,
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  sendBusinessAnswers(): boolean {
    if (!this.validateBusinessAnwser()) {
      return false;
    }

    this.setState({
      alertVisibility: false,
      alertMessage: "",
      loading: true,
    })

    const header = {
      "Content-Type": configJSON.businessQuestionsApiContentType,
      token: this.state.otpAuthToken
    };

    const httpBody = {
      account: {
        answers: [
          ...this.state.businessAnswersList,
          ...this.state.businessAnswersDropdown.map(dropdownAnswer => {
            return {
              question_id: dropdownAnswer.question_id,
              answer: dropdownAnswer.answer?.value
            }
          })
        ],
      },

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.businessAnswersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendBuisnessQuestionsAPiEndPoint.replace(':id', this.state.businessId)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sendBusinessQuestionsApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getBusinessQuestions() {
    const headers = {
      "Content-Type": configJSON.businessQuestionsApiContentType
    };

    const getBusinessQuestionsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.businessQuestionApiCallId = getBusinessQuestionsMsg.messageId;

    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBuisnessQuestionsAPiEndPoint
    );

    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getBusinessQuestionsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBusinessQuestionsApiMethodType
    );
    runEngine.sendMessage(getBusinessQuestionsMsg.id, getBusinessQuestionsMsg);
  }

  getCountriesName() {
    const headers = {
      "Content-Type": configJSON.businessQuestionsApiContentType
    };

    const getCountryNameMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countriesNameApiCallId = getCountryNameMsg.messageId;

    getCountryNameMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryNameAPiEndPoint
    );

    getCountryNameMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCountryNameMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBusinessQuestionsApiMethodType
    );
    runEngine.sendMessage(getCountryNameMsg.id, getCountryNameMsg);
  }

  getStatesName(country: string) {
    const headers = {
      "Content-Type": configJSON.businessQuestionsApiContentType
    };

    const getStateNameMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.statesNameApiCallId = getStateNameMsg.messageId;

    getStateNameMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateNameAPiEndPoint + country
    );

    getStateNameMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getStateNameMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBusinessQuestionsApiMethodType
    );
    runEngine.sendMessage(getStateNameMsg.id, getStateNameMsg);
  }

  getCitiesName(state: string) {
    const headers = {
      "Content-Type": configJSON.businessQuestionsApiContentType
    };

    const getCitiesNameMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.citiesNameApiCallId = getCitiesNameMsg.messageId;

    getCitiesNameMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCityNameAPiEndPoint}?country=${this.state.businessCountry?.value}&state=${state}`
    );

    getCitiesNameMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getCitiesNameMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getBusinessQuestionsApiMethodType
    );
    runEngine.sendMessage(getCitiesNameMsg.id, getCitiesNameMsg);
  }

  signIn() {
    this.setState({
      loading: true
    });

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      data: {
        type: "email_account",
        attributes: {
          email: this.state.email,
          password: this.state.password,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSignInCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signInApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserInfo(token: string) {
    this.setState({
      loading: true
    });

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetUserInfoCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserInfoApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  validateBusinessAnwser() {
    if (this.state.businessQuestionsList.length > this.state.businessAnswersList.length + this.state.businessAnswersDropdown.length) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    return true
  }

  validateSignUpFormBusinees() {
    if (
      this.state.accountType === 'Business' && (
      this.isStringNullOrBlank(this.state.businessName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.businessCountry?.value || "") ||
      this.isStringNullOrBlank(this.state.businessState?.value || "") ||
      this.isStringNullOrBlank(this.state.businessCity?.value || "") ||
      this.isStringNullOrBlank(this.state.businessStreet) ||
      this.isStringNullOrBlank(this.state.password))
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.validateBusinessAnwser()) return false;

    return true
  }

  validateSignUpEmailAndPassword() {
    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!(/[A-Z]/.test(this.state.password))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordUppercase);
      return false;
    }

    if (!(/[a-z]/.test(this.state.password))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordLowercase);
      return false;
    }

    if (!(/\d/.test(this.state.password))) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordNumber);
      return false;
    }

    if (!(this.state.password.length >= 8)) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordLength);
      return false;
    }

    if (this.state.password !== this.state.confirm_password) {
      this.showAlert(configJSON.errorTitle, this.errorPasswordMatch);
      return false;
    }

    if (this.state.acceptTermsAndConditions === false) {
      this.showAlert(configJSON.errorTitle, this.errorAcceptTermsAndConditions);
      return false;
    }

    return true
  }

  btnSignUpProps = {
    onPress: () => {
      if (this.state.accountType === "Business") {
        this.businessValidation()
      } else {
        this.createAccount()
      }
    }
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnAcceptPrivacyAndTermsProps = {
    onPress: () => {
      if (this.isToggling) return;
      this.isToggling = true;

      this.setState(
        (prevState) => ({ acceptTermsAndConditions: !prevState.acceptTermsAndConditions }),
        () => {
          this.isToggling = false;
        }
      )
    }
  }

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  btnSelectedIndividual = {
    onPress: () => this.setState({ selectedAccountType: 'User' })
  };

  btnSelectedBusiness = {
    onPress: () => this.setState({ selectedAccountType: 'Business' })
  };

  btnSelectedAccountType = {
    onPress: () => this.setState({ 
        accountType: this.state.selectedAccountType,
        screenStep: "SignUpFrom",
      }, () => {
        if (this.state.selectedAccountType === "Business") {
          this.getCountriesName();
        }
      })
  };

  btnSignUpBusiness = {
    onPress: () => this.createAccount()
  };

  txtBusinessNameProps = {
    onChangeText: (text: string) => {
      this.setState({ businessName: text });

      //@ts-ignore
      this.txtBusinessNameProps.value = text;
    }
  };

  txtBusinessCountryProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => {
      this.setState({ businessCountry: value, businessState: { label: "", value: "" }, businessCity: { label: "", value: "" } });
      this.getStatesName(value!.value);

      //@ts-ignore
      this.txtBusinessCountryProps.value = value;
      this.txtBusinessStateProps.onChange({ label: "", value: "" });
      this.txtBusinessCityProps.onChange({ label: "", value: "" });
    }
  };

  txtBusinessStateProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => {
      this.setState({ businessState: value, businessCity: { label: "", value: "" } });
      if (value?.value !== "") {
        this.getCitiesName(value!.value);
      }

      //@ts-ignore
      this.txtBusinessStateProps.value = value;
      this.txtBusinessCityProps.onChange({ label: "", value: "" });
    }
  };

  txtBusinessCityProps = {
    onChange: (value: ValueType<{ label: string; value: string; }, false>) => {
      this.setState({ businessCity: value });

      //@ts-ignore
      this.txtBusinessCityProps.value = value;
    }
  };

  txtBusinessStreetProps = {
    onChangeText: (text: string) => {
      this.setState({ businessStreet: text });

      //@ts-ignore
      this.txtBusinessStreetProps.value = text;
    }
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text.trim() });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text.trim();
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  handleSetDropDownBusinessAnwser = (question_id: number, answer: ValueType<{ label: string; value: string; }, false>) => {
    this.setState({
      businessAnswersDropdown: this.state.businessAnswersDropdown.find(answer => answer.question_id === question_id) ? [
        ...this.state.businessAnswersDropdown.filter(answer => answer.question_id !== question_id),
        {
          question_id,
          answer: answer
        }
      ] : [
        ...this.state.businessAnswersDropdown,
        {
          question_id,
          answer: answer
        }
      ]
    })
  }

  handleSetBusinessAnwser = (question_id: number, text: string) => {
    this.setState({
      businessAnswersList: this.state.businessAnswersList.find(answer => answer.question_id === question_id) ? [
        ...this.state.businessAnswersList.filter(answer => answer.question_id !== question_id),
        {
          question_id,
          answer: text
        }
      ] : [
        ...this.state.businessAnswersList,
        {
          question_id,
          answer: text
        }
      ]
    })
  }

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ 
        confirm_password: text,
        passwordValidations: {
          uppercase: this.state.passwordValidations.uppercase,
          lowercase: this.state.passwordValidations.lowercase,
          number: this.state.passwordValidations.number,
          length: this.state.passwordValidations.length,
          match: text === this.state.password
        },
      });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({
        password: text,
        passwordValidations: {
          uppercase: /[A-Z]/.test(text),
          lowercase: /[a-z]/.test(text),
          number: /\d/.test(text),
          length: text.length >= 8,
          match: text === this.state.confirm_password
        },
      });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
